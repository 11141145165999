<!-- Header.vue -->
<template>
  <div class="text-center">
    <div>
      <h1 class="text-2xl font-sans text-white p-5">CURRENT PRIZE POOL</h1>
    </div>
    <div
      class="animate-pulse border-2 border-green-600 bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90% mb-5 text-3xl"
    >
      {{ prizePool }} $FLAPPY
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "RightButtonArea",
  computed: {
    ...mapState({
      rawPrizePool: (state) => state.prizePool,
    }),
    prizePool() {
      const value = parseFloat(this.rawPrizePool);
      return isNaN(value) ? "0.00" : value.toFixed(2);
    },
  },
};
</script>

<style>
/* Header-specific styles can go here, if needed */
</style>
